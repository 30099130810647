import { useCallback } from 'react';
import { Config, Cert as CertType } from './types';
import { readFileAsString } from '../libs/utils';
import './Cert.css';
export const defaultConfig: string[] = [];

interface CertProps {
  handleConfigChange: (key: keyof Config, value: any) => void;
  config: CertType[];
}

function Certificate(props: CertProps) {
  const onAddCert = useCallback(
    (base64, filename) => {
      const newConfig = [
        ...props.config,
        {
          base64,
          filename,
        },
      ];

      props.handleConfigChange('certificates', newConfig);
    },
    [props]
  );

  return (
    <div className="form-group">
      <label id="certTitle">User Certificates</label>
      <div className="certificates-container">
        {props.config.map((cert) => (
          <input id="certFileNameInput" className="input" type="text" value={cert.filename} disabled />
        ))}
      </div>
      <div className="input-container">
        <div className="file is-small">
          <label className="file-label">
            <input
              id="certFileInput"
              className="file-input"
              type="file"
              name="resume"
              onChange={async (e) => {
                if (!e.target.files || e.target.files.length === 0) return;
                const file = e.target.files[0];
                const base64 = (await readFileAsString(file)) as string;
                onAddCert(base64, file.name);
              }}
              accept="application/pkix-cert, application/x-x509-ca-cert"
            />
            <span className="button import-Certs">
              <span id="importSpan" className="file-label">
                Import Certificate
              </span>
            </span>
          </label>
        </div>
      </div>
    </div>
  );
}

export default Certificate;
