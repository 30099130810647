import { useEffect, useState } from 'react';
import _ from 'lodash';
import { readFileAsString } from '../libs/utils';
import { NetworkConfig, Config } from './types';
import { DeepMap, FieldValues, FieldError } from 'react-hook-form';
import wifi from '../icons/wifi.svg';
import ethernet from '../icons/ethernet.svg';

export const defaultConfig: NetworkConfig = {
  auth_type: 'basic',
  ssid: '',
  hidden: false,
  password: '',
  enterprise_auth: {
    mode: 'tls',
    identity: '',
    private_key_password: '',
    ca: '',
    cert: '',
    private_key: '',
    password: '',
  },
  dns: [''],
  fixed_ipv4: {
    method: 'auto',
    default_gateway: '',
    address: '',
  },
};

interface NetworkProps {
  handleConfigChange: (key: keyof Config, value: any) => void;
  config: NetworkConfig;
  register: any;
  errors: DeepMap<FieldValues, FieldError>;
  setValue: any;
  unregister: any;
}

export default function Network(props: NetworkProps) {
  const { config: network } = props;
  const handleConfigChange = (path: string, value: string | boolean) => {
    const newConfig = {
      ...network,
    };

    if (path === 'mode' && (!network.mode || network.mode === 'wifi') && network.auth_type === 'basic') {
      newConfig.auth_type = 'none';
    }

    // TODO This will be disabled for scos v1.4.11 because we do not have time to test enterprise with ethernet connection yet so it always be none
    if (path === 'mode' && value === 'ethernet') {
      newConfig.auth_type = 'none';
    }

    _.set(newConfig, path, value);
    props.handleConfigChange('network', newConfig);
  };

  return (
    <>
      <NetworkType {...props} config={network} handleConfigChange={handleConfigChange} />
      {network.mode && <Authentication {...props} config={network} handleConfigChange={handleConfigChange} />}
    </>
  );
}

interface Props {
  config: NetworkConfig;
  handleConfigChange: (path: string, value: string | boolean) => void;
  register: any;
  errors: DeepMap<FieldValues, FieldError>;
  setValue: any;
  unregister: any;
  scosVersion?: string;
}

function NetworkType(props: Props) {
  const { config, handleConfigChange } = props;
  const [selectedNetworkType, setSelectedNetworkType] = useState<'wifi' | 'ethernet'>('wifi');

  useEffect(() => {
    if (config.mode === undefined) {
      handleConfigChange('mode', 'wifi');
    }
  });

  const setNetworkType = (path: string, value: 'wifi' | 'ethernet') => {
    setSelectedNetworkType(value);
    handleConfigChange(path, value);
  };

  return (
    <>
      <h3>Network</h3>
      <div className="form-group">
        <label>Choose Your Network Type</label>
        <div className="input-container">
          <div className="button-group">
            <button
              id="optionWifiCheckbox"
              type="button"
              className={`button  network-option ${selectedNetworkType === 'wifi' ? 'active' : ''}`}
              onClick={() => setNetworkType('mode', 'wifi')}>
              <img src={wifi} alt="wifi" className="wifi" />
              WiFi
            </button>
            <button
              id="optionEthernetCheckbox"
              type="button"
              className={`button network-option ${selectedNetworkType === 'ethernet' ? 'active' : ''}`}
              onClick={() => setNetworkType('mode', 'ethernet')}>
              <img src={ethernet} alt="ethernet" className="ethernet" />
              Ethernet
            </button>
          </div>
        </div>
      </div>
      {(config.mode === 'wifi' || selectedNetworkType === 'wifi') && <Identity {...props} />}
    </>
  );
}

function Identity({ config, handleConfigChange }: Props) {
  return (
    <div className="form-group">
      <label id="ssidTitle">Network Name (SSID)</label>
      <div className="input-container">
        <div className="field">
          <div className="control">
            <input
              id="ssidInput"
              className="input"
              type="text"
              value={config.ssid}
              onChange={(e) => {
                handleConfigChange('ssid', e.target.value);
              }}
              autoFocus
            />
            <small>
              Only 2.4GHz WiFi supported on Station P1,
              <br />
              2.4/5GHz WiFi supported on Station P1 Pro.
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

function BasicAuth({ config, handleConfigChange }: Props) {
  return (
    <>
      <div className="form-group">
        <label id="passwordTitle">Password</label>
        <div className="input-container">
          <div className="field">
            <div className="control">
              <input
                id="passwordInput"
                className="input"
                type="password"
                value={config.password}
                onChange={(e) => {
                  handleConfigChange('password', e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function AuthOptions({ config, handleConfigChange }: Props) {
  return (
    <div className="form-group">
      <label>Authentication</label>
      <div className="input-container">
        <div className="field">
          <div className="control">
            <div className="select is-fullwidth">
              <select
                id="authSelect"
                onChange={(e) => handleConfigChange('auth_type', e.target.value)}
                value={config.auth_type}>
                {config.mode === 'wifi' && <option value="basic">Basic</option>}
                <option value="enterprise">Enterprise</option>
                <option value="none">None</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function EnterpriseAuth(props: Props) {
  const { config, handleConfigChange } = props;

  return (
    <>
      <div className="form-group">
        <label id="securityTitle">Security</label>
        <div className="input-container">
          <div className="field">
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  id="securitySelect"
                  onChange={(e) => {
                    handleConfigChange('enterprise_auth.mode', e.target.value);
                  }}>
                  <option value="tls">TLS</option>
                  <option value="peap">Protected EAP (PEAP)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label id="identityTitle">Identity</label>
        <div className="input-container">
          <div className="field">
            <div className="control">
              <input
                id="identityInput"
                className="input"
                type="text"
                onChange={(e) => {
                  handleConfigChange('enterprise_auth.identity', e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {config.enterprise_auth.mode === 'tls' ? <Tls {...props} /> : <Peap {...props} />}
    </>
  );
}

function Tls({ handleConfigChange }: Props) {
  const [caFile, setCaFile] = useState<File>();
  const [certFile, setCertFile] = useState<File>();
  const [privateKeyFile, setPrivateKeyFile] = useState<File>();

  return (
    <div>
      <>
        <div className="form-group">
          <label id="tlsPriKeyPasswordTitle">User private key password</label>
          <div className="input-container">
            <div className="field">
              <div className="control">
                <input
                  key="tls-password"
                  id="tlsPriKeyPasswordInput"
                  className="input "
                  type="text"
                  onChange={(e) => handleConfigChange('enterprise_auth.private_key_password', e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label id="tlsCaTitle">CA certificates</label>
          <div className="input-container">
            <div className="file is-small">
              <label className="file-label">
                <input
                  key="tls-ca"
                  className="file-input"
                  type="file"
                  onChange={async (e) => {
                    if (!e.target.files) return;
                    if (e.target.files[0].name.split('.').pop() !== 'pem') {
                      handleConfigChange('enterprise_auth.ca', '');
                    } else {
                      setCaFile(e.target.files[0]);
                      const content = (await readFileAsString(e.target.files[0])) as string;
                      handleConfigChange('enterprise_auth.ca', content);
                    }
                  }}
                />
                <span className="file-cta">
                  <span id="tlsCaFile" className="file-label">
                    Choose File
                  </span>
                </span>
              </label>
              <span id="tlsCaName" className="filename">
                {caFile?.name || 'No file chosen'}
              </span>
            </div>
          </div>
          <small>Only .pem format supported.</small>
        </div>
        <div className="form-group">
          <label id="tlsCertTitle">User certificates</label>
          <div className="input-container">
            <div className="file is-small">
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  onChange={async (e) => {
                    if (!e.target.files) return;
                    if (e.target.files[0].name.split('.').pop() !== 'pem') {
                      handleConfigChange('enterprise_auth.cert', '');
                    } else {
                      setCertFile(e.target.files[0]);
                      const content = (await readFileAsString(e.target.files[0])) as string;
                      handleConfigChange('enterprise_auth.cert', content);
                    }
                  }}
                />
                <span className="file-cta">
                  <span id="tlsCertFile" className="file-label">
                    Choose File
                  </span>
                </span>
              </label>
              <span id="tlsCertName" className="filename">
                {certFile?.name || 'No file chosen'}
              </span>
            </div>
          </div>
          <small>Only .pem format supported.</small>
        </div>
        <div className="form-group">
          <label id="tlsPriKeyTitle">Private key</label>
          <div className="input-container">
            <div className="file is-small">
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  onChange={async (e) => {
                    if (!e.target.files) return;
                    setPrivateKeyFile(e.target.files[0]);
                    const content = (await readFileAsString(e.target.files[0])) as string;
                    handleConfigChange('enterprise_auth.private_key', content);
                  }}
                />
                <span className="file-cta">
                  <span id="tlsPriKeyFile" className="file-label">
                    Choose File
                  </span>
                </span>
              </label>
              <span id="tlsPriKeyName" className="filename">
                {privateKeyFile?.name || 'No file chosen'}
              </span>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

function Peap({ handleConfigChange }: Props) {
  const [caFile, setCaFile] = useState<File>();

  return (
    <div>
      <>
        <div className="form-group">
          <label id="peapPasswordTitle">Password</label>
          <div className="input-container">
            <div className="field">
              <div className="control">
                <input
                  key="peap-password"
                  id="peapPasswordInput"
                  className="input"
                  type="password"
                  onChange={(e) => {
                    handleConfigChange('enterprise_auth.password', e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label id="peapCaTitle">CA certificates</label>
          <div className="input-container">
            <div className="file is-small">
              <label className="file-label">
                <input
                  key="peap-ca"
                  className="file-input"
                  type="file"
                  onChange={async (e) => {
                    if (!e.target.files) return;
                    if (e.target.files[0].name.split('.').pop() !== 'pem') {
                      handleConfigChange('enterprise_auth.ca', '');
                    } else {
                      setCaFile(e.target.files[0]);
                      const content = (await readFileAsString(e.target.files[0])) as string;
                      handleConfigChange('enterprise_auth.ca', content);
                    }
                  }}
                />
                <span className="file-cta">
                  <span id="peapCaFile" className="file-label">
                    Choose File
                  </span>
                </span>
              </label>
              <span id="peapCaName" className="filename">
                {caFile?.name || 'No file chosen'}
              </span>
            </div>
          </div>
          <small>Only .pem format supported</small>
        </div>
      </>
    </div>
  );
}

function Authentication(props: Props) {
  const { config } = props;

  if (config.mode === 'ethernet') return <></>;

  return (
    <>
      <AuthOptions {...props} />
      {config.auth_type === 'basic' && <BasicAuth {...props} />}
      {config.auth_type === 'enterprise' && <EnterpriseAuth {...props} />}
    </>
  );
}
