export function download(filename: string, text: string) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function encodeBase64(value: string) {
  return btoa(unescape(encodeURIComponent(value)));
}

export function decodeBase64(value: string) {
  return atob(btoa(unescape(encodeURIComponent(value))));
}

export function readFileAsString(fileInput: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      // console.log('File content:', event?.target?.result);
      // resolve(event.target.result)
      if (event?.target?.result !== undefined) {
        resolve(encodeBase64(event.target.result as string));
      }
    };
    reader.onerror = reject;
    reader.readAsText(fileInput);
  });
}

export function isIpAddress(ipaddress: string) {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipaddress
    )
  ) {
    return true;
  }
  return false;
}
